'use strict';
class AnalyticsController {

    constructor(SSUtilService, SSHttpService, SSAlertService, SSUserService, SSAnalyticsService) {
        const self = this;

        self.SSUtilService = SSUtilService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;
        self.SSAnalyticsService = SSAnalyticsService;

        self.PLACEMENT = SHOWSUITE.PLACEMENT;

        if (self.SSUserService.getUser() && !self.SSUserService.isCustomer()) {
            self.init();
        }
    }

    init() {
        const self = this;

        // Trigger View Event for Analytics Page
        self.SSAnalyticsService.saveAnalyticEvent({
                event: self.SSAnalyticsService.ANALYTICS_EVENT_VIEWS_DMS_ANALYTICS,
                user_id: self.SSUtilService.getFromLocalStorage('user').id
            }
        );

        self.fetchAnalyticsGraphs();
    }

    fetchAnalyticsGraphs() {
        const self = this;

        let url = 'sec/analytics/graphs';
        let params = {
            placement: self.PLACEMENT
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            let temp_graphs = response.data.graphs;
            if (temp_graphs && temp_graphs.length > 0) {
                temp_graphs[0].type = 'column';
            }
            self.graphs = temp_graphs;
        });
    }

}

AnalyticsController.$inject = ['SSUtilService', 'SSHttpService', 'SSAlertService', 'SSUserService', 'SSAnalyticsService'];
app.controller('AnalyticsController', AnalyticsController);