"use strict";

class SidebarController {

    constructor($window, SSUtilService, SSAlertService, SSUserService) {
        const self = this;

        self.$window = $window;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;

        self.init();
    }

    init() {
        const self = this;
    }

    pathMatches(pathname, criteria) {
        const self = this;

        let matches = false;
        let _pathname = self.$window.location.pathname;

        if (!criteria || criteria == '=') {
            matches = (_pathname == pathname || _pathname == pathname + '/');
        } else if (criteria == '^') {
            matches = startsWith(_pathname, pathname);
        }

        return matches;
    }
}

SidebarController.$inject = ['$window', 'SSUtilService', 'SSAlertService', 'SSUserService'];
app.controller('SidebarController', SidebarController);
