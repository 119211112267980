"use strict";

class TopbarController {
    constructor($rootScope, $window, $cookies, SSUtilService, SSUserService, $timeout, SSHttpService, SSAlertService, SSConfirmationService, SSFirebaseMessagingService, Idle) {
        const self = this;

        self.$window = $window;
        self.$cookies = $cookies;
        self.$rootScope = $rootScope;

        self.SSUtilService = SSUtilService;
        self.SSUserService = SSUserService;
        self.SSHttpService = SSHttpService;
        self.SSAlertService = SSAlertService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSFirebaseMessagingService = SSFirebaseMessagingService;

        self.$timeout = $timeout;

        self.Idle = Idle;
        self.warnCheck = false;

        self.ANALYTICS_EVENT_SESSION_TIMEOUT = "sessions_timeout";
        self.ANALYTICS_EVENT_SESSION_RESUMED = 'sessions_resumed';


        self.init();

        //Currently only support DMS - TODO will need to update AMS when AMS revamps
        let pathname = self.$window.location.pathname;

        if (startsWith(pathname, '/super') && self.SSUserService.isSuper()) {
            self.getSuperLeadBadge();
        }
        if (startsWith(pathname, '/developer') && self.SSUserService.isDeveloper()) {
            self.getNotificationBadge();
            self.getDeveloperLeadBadge();
        }
        if (startsWith(pathname, '/broker') && self.SSUserService.isBroker()) {
            self.getNotificationBadge();
            self.getBrokerLeadBadge();
        }
    }

    init() {
        const self = this;

        if(self.SSUserService.getUser()){
            self.SSUserService.pingUserSession();
            if(self.SSUserService.isDeveloper()){
                self.deployIdleSetup();
            }
        }

        self.agreementModal = self.SSUserService.agreementModal;

        if (!self.SSUserService.getUser()) {
            self.SSUserService.showLoginModal();
        } else {

            let pathname = self.$window.location.pathname;
            let redirectToHome = false;

            if (startsWith(pathname, '/super') && !self.SSUserService.isSuper()) {
                redirectToHome = true;
            } else if (startsWith(pathname, '/admin') && !self.SSUserService.isAdmin()) {
                redirectToHome = true;
            } else if (startsWith(pathname, '/developer') && !self.SSUserService.isDeveloper()) {
                redirectToHome = true;
            } else if (startsWith(pathname, '/agency') && !self.SSUserService.isAgency()) {
                redirectToHome = true;
            } else if (startsWith(pathname, '/broker') && !self.SSUserService.isBroker()) {
                redirectToHome = true;
            } else if (startsWith(pathname, '/lawfirm') && !self.SSUserService.isLawfirm()) {
                redirectToHome = true;
            }

            if (redirectToHome) {
                loading(true);
                self.$window.location.href = self.SSUserService.getHomeUrl();
            }

        }

        $(document).click(function(e){
            if( $(e.target).closest(".topbar-dropdown").length > 0 ) {
                return false;
            } else if ($(e.target).closest("#topbar-dropdown-link").length > 0) {
                return false;
            }

            if ($('.topbar-dropdown').is(":visible")) {
                $('.topbar-dropdown').toggle();
            }
        });
    }

    //------------ Developer Idle Settings ----------
    getSetting(settings, setting_name) {
        const self = this;

        if (!(settings && setting_name)) {
            return null;
        }

        let setting = {};

        try {
            setting = settings.find(x => x.name == setting_name);

            if (setting
                && setting.value
                && typeof setting.value == "string"
            ) {
                setting.value = JSON.parse(setting.value);
            }
        } catch (ex) {
            console.log(ex);
        }

        return setting;
    }

    deployIdleSetup() {
        const self = this;

        let url = 'sec/developer/settings';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.data && response.data.developer_settings) {


                self.session_timeout = self.getSetting(response.data.developer_settings, "session_timeout");
                if (self.session_timeout && self.session_timeout.hasOwnProperty('value') && self.session_timeout.value.enable){
                    self.Idle.setIdle(self.session_timeout.value.duration * 60);
                    self.userIdleSetup();
                    self.Idle.watch();
                }


            }
        });
    }

    userIdleSetup() {
        const self = this;
        console.log("Setting Up Idle Timeout");

        let deregisterListener = self.$rootScope.$on('IdleStart', function () {
            // the user appears to have gone idle
        });

        self.$rootScope.$on('IdleWarn', function (e, countdown) {
            // follows after the IdleStart event, but includes a countdown until the user is considered timed out
            // the countdown arg is the number of seconds remaining until then.
            // you can change the title or display a warning dialog from here.
            // you can let them resume their session by calling Idle.watch()
            $('#confirmationModal div.modal-body p').text(`You are going to be logged out in ${countdown} seconds. Do you want to keep session alive?`);
            if (!self.warnCheck) {
                self.warnCheck = true;
                self.SSConfirmationService.getConfirmed(
                    'Warning! Session Timeout',
                    `You are going to be logged out in ${countdown} seconds. Do you want to keep session alive?`,
                    self.SSConfirmationService.TYPE_YES,
                    function () {
                        self.Idle.watch();
                        self.warnCheck = false;
                    });
            }
        });

        self.$rootScope.$on('IdleTimeout', function () {
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them

            // This call will deregister the listener and stop it. Also, logout user call in next line is added.
            deregisterListener();
            self.Idle.unwatch();
            self.saveAnalyticEvent(self.ANALYTICS_EVENT_SESSION_TIMEOUT);
            self.SSUserService.logoutUser();
        });

        self.$rootScope.$on('IdleEnd', function () {
            // the user has come back from AFK and is doing stuff. if you are warning them, you can use this to hide the dialog
            console.log("User Active Again");
            self.warnCheck = false;
            $('#confirmationModal').modal('hide');
            self.saveAnalyticEvent(self.ANALYTICS_EVENT_SESSION_RESUMED);
        });

        // self.$rootScope.$on('Keepalive', function() {
        //     // do something to keep the user's session alive
        // });


        // Note: commented out code to start Idle watch at app start. This has to go at the end after config.
        // .run(function (Idle) {
        //         // start watching when the app runs. also starts the Keepalive service by default.
        //         Idle.watch();
        //     });
    }

    //------------- Developer Idle Settings End -------

    agreementAcceptance(){
        const self = this;

        let param = {
            'user_agreement': true
        };

        self.SSConfirmationService.getConfirmed(
            'Confirm!',
            'Are you sure you agree?.',
            self.SSConfirmationService.TYPE_DEFAULT,
            function() {

                loading(true);
                self.SSHttpService.putAPIRequest('sec/me', param).then(
                    function (responseObj) {
                        loading(false);

                        if (responseObj instanceof Error) {
                            self.SSAlertService.parseAndDisplayError(responseObj);
                            console.log(responseObj.data);
                            return;
                        }

                        if (responseObj.data.user) {
                            self.SSUtilService.deleteFromLocalStorage('user');
                            self.SSUserService.setUser(responseObj.data.user);
                            self.sendAgreementAcceptanceDoc();

                        }
                        self.SSAlertService.success('Success!', 'Agreement accepted successfully!');

                    });

            }
        );

    }

    sendAgreementAcceptanceDoc(){
        const self = this;
        let url = 'sec/developer/accept-agreement';
        self.SSHttpService.postAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                return;
            }
        });
    }

    toggleProfileNavBar() {
        const self = this;

        self.$timeout(function() {
            $('.profile-dropdown').toggle();
        });
    }

    toggleLanguageNavBar() {
        const self = this;

        self.$timeout(function() {
            $('.language-select').toggle();
        });
    }

    languageChanged(language) {
        const self = this;
        self.$cookies.put('lang', language);
        self.$window.location.reload();
    }

    viewProfile() {
        window.location.href = '/developer/settings/my-profile';
    }

    viewLawfirmProfile() {
        window.location.href = '/lawfirm/settings/my-profile';
    }

    viewLawyerProfile() {
        window.location.href = '/lawyer/settings/my-profile';
    }

    viewSuperProfile() {
        window.location.href = '/super/settings/my-profile';
    }

    viewBrokerProfile() {
        window.location.href = '/broker/settings/profile';
    }

    viewAMPProfile() {
        window.location.href = '/amp/settings/my-profile';
    }

    viewInvites() {
        window.location.href = '/developer/invites';
    }

    viewSuperInvites() {
        window.location.href = '/super/invites';   
    }

    viewActivitiesLog() {
        // window.location.href = '/developer/settings/my-profile';
    }

    getNotificationBadge() {
        const self = this;

        self.unreadNotificationCount = 0;

        let url = 'notifications';

        let params = {
            offset: 0,
            status: 0
        };

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                return;
            }

            if (response.data.notifications) {
                self.unreadNotificationCount = response.data.notifications.length;
            }
        });
    }

    getDeveloperLeadBadge() {
        const self = this;

        if (!self.SSUserService.hasPermission('DMS_VW_LEADS')) {
            console.log('Permission denied: DMS_VW_LEADS')
            return;
        }

        let url = 'sec/developer/leads';

        let params = {
            return_new_leads_count: 1,
        };

        self.newLeadsCount = 0;

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                return;
            }

            self.newLeadsCount = response.data.new_leads_count;
        });
    }

    getSuperLeadBadge() {
        const self = this;

        let url = 'sec/super/leads';

        let params = {
            return_count_only: 1,
            status: "10, 11, 40"
        };

        self.newLeadsCount = 0;

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                return;
            }

            if(response.data.leads){
                $.each(response.data.leads, function(key, value){
                    self.newLeadsCount += value;
                });
            }
        });
    }

    getBrokerLeadBadge() {
        const self = this;

        let url = 'sec/broker/leads';

        let params = {
            return_new_leads_count: 1,
        };

        self.newLeadsCount = 0;

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                return;
            }
            
            self.newLeadsCount = response.data.new_leads_count;
        });
    }

    saveAnalyticEvent(eventType){
        let self = this;

        let params = {
            event: eventType,
            user_id: self.SSUserService.getUser().id
        };

        self.SSHttpService.postAPIRequest('analytics/post', params).then(
            function (responseObj) {
                if (responseObj instanceof Error) {
                    console.error('Error while registering analytic event');
                }
            }
        );
    }

}

TopbarController.$inject = ['$rootScope', '$window', '$cookies', 'SSUtilService', 'SSUserService', '$timeout', 'SSHttpService', 'SSAlertService', 'SSConfirmationService','SSFirebaseMessagingService', 'Idle'];
app.controller('TopbarController', TopbarController)
    .config(function (IdleProvider, KeepaliveProvider) {
        // configure Idle settings
        IdleProvider.idle(1800); // in seconds
        IdleProvider.timeout(30); // in seconds

        IdleProvider.interrupt('keydown mousedown');
    });
