"use strict";

class RegisterController {
    constructor($element, $parse, $window, SSHttpService, SSUtilService, SSAlertService, SSUserService) {
        const self = this;

        self.$element = $element;
        self.$parse = $parse;
        self.$window = $window;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;
        self.SSUserService = SSUserService;

        self.data = SHOWSUITE.data;

        self.init();
    }

    init() {
        const self = this;

        if (!self.data.user_invite) {
            self.SSAlertService.danger('Sorry! You don\'t have a valid invite for Showsuite account registration.');
        }

        self.userInvite = self.data.user_invite;

        self.registerParams = {};
        self.registerErrors = {};

        if (self.userInvite) {
            self.registerParams.invite_code = self.userInvite.code;
            self.registerParams.user_email = self.userInvite.email;
        }

        self.maskPhoneNumber(self, 'ctrl.', 'RegisterForm');
    }

    maskPhoneNumber(context, contextPrefix, formId) {
        let self = this;

        let parent = jQuery;
        if (formId) {
            parent = jQuery('#' + formId);
        }

        jQuery.each(parent.find('input[type=tel]'), function (i, el) {
            let field = jQuery(el);

            let allowDropdown = field.attr('allowDropdown');
            allowDropdown = !allowDropdown || allowDropdown !== 'false';

            field.intlTelInput({
                initialCountry: 'sg',
                geoIpLookup: self.SSUtilService.geoIpLookup,
                allowDropdown: allowDropdown,
                preferredCountries: ['sg']
            });

            let model = field.attr('ng-model');
            if (model) {
                if (startsWith(model, contextPrefix)) {
                    model = model.substring(contextPrefix.length);
                }
                let value = self.$parse(model)(context);
                if (value) {
                    field.intlTelInput('setNumber', value);
                }
            }

            field.on("keyup change", function () {

                field.parent().removeClass("has-error");
            });
            field.blur(function () {
                var currentFormat = (field.val() && field.val()[0] === "+") ? intlTelInputUtils.numberFormat.INTERNATIONAL : intlTelInputUtils.numberFormat.NATIONAL;
                field.val(field.intlTelInput("getNumber", currentFormat));
                field.parent().removeClass("has-error");
                if (!field.intlTelInput("isValidNumber")) {
                    field.parent().addClass("has-error");
                    if (model) {
                        //self.$parse(model).assign(context, ""); // Don't remove the number if invalid, unless we upgrade to version of latest intl-tel-input
                    }
                } else {
                    if (model) {
                        self.$parse(model).assign(context, field.intlTelInput("getNumber"));
                    }
                }
            });
        });
    }

    registerUser() {
        const self = this;

        if (!self.SSUtilService.validateForm(self, 'ctrl', jQuery('#RegisterForm')))
            return;

        let url = 'register-admin';

        loading(true);
        self.SSHttpService.postAPIRequest(url, self.registerParams).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                self.registerErrors = self.SSHttpService.parseErrors(response);
                return;
            }

            self.userInvite = null;
            self.SSAlertService.success('Account is registered successfully. Please check your email to activate the account.');
        });
    }

}

RegisterController.$inject = ['$element', '$parse', '$window', 'SSHttpService', 'SSUtilService', 'SSAlertService', 'SSUserService'];
app.controller('RegisterController', RegisterController);
