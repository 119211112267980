"use strict";

class AdminProfileController {
    constructor($element, $parse, $timeout, SSHttpService, SSUtilService, SSAlertService, OTPService, SSUserService, SSConfirmationService, SSAwsService, SSAppService) {
        const self = this;

        self.$element = $element;
        self.$parse = $parse;
        self.$timeout = $timeout;

        self.SSHttpService = SSHttpService;
        self.SSUtilService = SSUtilService;
        self.SSAlertService = SSAlertService;
        self.OTPService = OTPService;
        self.SSUserService = SSUserService;
        self.SSConfirmationService = SSConfirmationService;
        self.SSAwsService = SSAwsService;
        self.SSAppService = SSAppService;

        self.SMS_SERVICE_TOKEN_SENT_SUCCESSFULLY = SHOWSUITE.SMS_SERVICE_TOKEN_SENT_SUCCESSFULLY; // 1
        self.SMS_SERVICE_TOKEN_WAS_ALREADY_SENT = SHOWSUITE.SMS_SERVICE_TOKEN_WAS_ALREADY_SENT; // 2
        self.SMS_SERVICE_TOKEN_SENT_ERROR = SHOWSUITE.SMS_SERVICE_TOKEN_SENT_ERROR;  // 3
        self.SMS_SERVICE_TOKEN_MISSING_REQUIRED_PARAMS = SHOWSUITE.SMS_SERVICE_TOKEN_MISSING_REQUIRED_PARAMS;  // 4

        self.ASSET_MODEL_SALES_CONSULTANT_SIGNATURE = SHOWSUITE.ASSET_MODEL_SALES_CONSULTANT_SIGNATURE;
        
        self.DEVELOPER_SETTINGS_PASSWORD_VALIDATION = SHOWSUITE.DEVELOPER_SETTINGS_PASSWORD_VALIDATION;
        
        self.TYPE_ENABLE_2FA = 1;
        
        self.showImageUri = null;

        let user = self.SSUserService.getUser();
        if (!user) {
            self.SSUserService.showLoginModal();
            return;
        }
        self.user = user;

        self.init();
    }

    init() {
        const self = this;

        self.userParams = {
            user_first_name: self.user.first_name,
            user_last_name: self.user.last_name,
            user_display_name: self.user.display_name,
            user_email: self.user.email,
            user_phone1: self.user.phone1,
            user_allowed_ips: self.user.allowed_ips,
        };

        self.maskPhoneNumber(self, 'ctrl.');

        self.user_roles = self.SSUserService.getUserRoles();

        let user_role = self.user_roles.find(x => x.name === self.user.role);

        if (user_role) {
            self.user.role_display_name = user_role.display_name;
        } else {
            self.user.role_display_name = "No role selected";
        }

        if (self.SSUserService.isSuper()) {
            self._setIdAddressField();
        }
        if (self.SSUserService.isDeveloper()) {
            self.getDeveloperSettings();
        }

        self.getSalesConsultant();
    }

    hasViewPermission() {
        const self = this;

        if (self.SSUserService.isDeveloper()) {
            if (self.SSUserService.hasPermission('DMS_VIEW_MY_PROFILE')) {
                return true;
            } else {
                return false;
            }
        } else {
            self.has_access = false;
            if (self.SSUserService.isLawfirm()) {
                self.has_access = true;
            }
            return true;
        }
    }

    hasEditPermission() {
        const self = this;

        if (self.SSUserService.isDeveloper()) {
            if (self.SSUserService.hasPermission('DMS_EDIT_MY_PROFILE')) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    saveUser() {
        const self = this;

        if (self._validationIpRestriction())
            return;
        if (!self.SSUtilService.validateForm(self, 'ctrl', self.$element.find('#MyAccountUserDetails')))
            return;

        self.userErrors = {};
        let params = angular.copy(self.userParams);
        delete params.user_email;

        let url = 'sec/me';

        loading(true);
        self.SSHttpService.putAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);

                jQuery.each(response.data, function (key, value) {
                    try {
                        self.userErrors[key] = value[0];
                    } catch (ex) {
                    }
                });

                return;
            }

            self.SSAlertService.success('User details updated successfully.');

            self.SSUserService.setUser(response.data.user);
            self.init();
        });
    }

    maskPhoneNumber(context, contextPrefix, formId) {
        let self = this;

        let parent = jQuery;
        if (formId) {
            parent = jQuery('#' + formId);
        }

        jQuery.each(parent.find('input[type=tel]'), function (i, el) {
            let field = jQuery(el);

            let allowDropdown = field.attr('allowDropdown');
            allowDropdown = !allowDropdown || allowDropdown !== 'false';

            field.intlTelInput({
                initialCountry: 'sg',
                geoIpLookup: self.SSUtilService.geoIpLookup,
                allowDropdown: allowDropdown,
                preferredCountries: ['sg']
            });

            let model = field.attr('ng-model');
            if (model) {
                if (startsWith(model, contextPrefix)) {
                    model = model.substring(contextPrefix.length);
                }
                let value = self.$parse(model)(context);
                if (value) {
                    field.intlTelInput('setNumber', value);
                }
            }

            field.on("keyup change", function () {

                field.parent().removeClass("has-error");
            });
            field.blur(function () {
                var currentFormat = (field.val() && field.val()[0] === "+") ? intlTelInputUtils.numberFormat.INTERNATIONAL : intlTelInputUtils.numberFormat.NATIONAL;
                field.val(field.intlTelInput("getNumber", currentFormat));
                field.parent().removeClass("has-error");
                if (!field.intlTelInput("isValidNumber")) {
                    field.parent().addClass("has-error");
                    if (model) {
                        //self.$parse(model).assign(context, ""); // Don't remove the number if invalid, unless we upgrade to version of latest intl-tel-input
                    }
                } else {
                    if (model) {
                        self.$parse(model).assign(context, field.intlTelInput("getNumber"));
                    }
                }
            });
        });
    }

    _updateTwoFA() {
        const self = this;

        self.OTPService.getVerified(self.OTPService.TYPE_SESSION_2FA, function (data) {
            self.updateTwoFA();
        });
    }

    updateTwoFA() {
        const self = this;

        self.twofa_code = '';
        self.errTwoFACode = '';
        if (!self.user.phone1) {
            self.SSAlertService.danger('Error!', 'Please update your contact number.');
            return;
        }

        loading(true);
        self.SSHttpService.postAPIRequest('sec/me/toggle2fa').then(function (responseObj) {
            loading(false);

            if (responseObj instanceof Error) {

                if (responseObj.sub_code == self.SMS_SERVICE_TOKEN_SENT_SUCCESSFULLY) {
                    self.showUpdate2FAModal(responseObj);
                } else if (responseObj.sub_code == self.SMS_SERVICE_TOKEN_WAS_ALREADY_SENT) {
                    self.showUpdate2FAModal(responseObj);
                } else if (responseObj.sub_code == self.SMS_SERVICE_TOKEN_SENT_ERROR) {
                    self.SSAlertService.danger('Error!', 'Please update your contact number.');
                    self.showUpdate2FAModal(responseObj);
                } else {
                    self.SSAlertService.parseAndDisplayError(responseObj);
                }
            } else if (self.user.twofa_enabled) {
                self.SSConfirmationService.getConfirmed(
                    'Confirm!',
                    '2FA updated successfully.',
                    self.SSConfirmationService.TYPE_DEFAULT,
                    function () {
                        self.user.twofa_enabled = 0;
                    }
                );
            }
        });
    }

    ipRestrictionCheck(check) {
        const self = this;

        if (check) {
            self.SSConfirmationService.getConfirmed(
                'Confirm!',
                'Are you sure you want to Enable IP restriction?',
                self.SSConfirmationService.TYPE_YES_NO,
                function () {
                    self.IpEnabledCheck = check;
                }
            );
        } else {
            self.SSConfirmationService.getConfirmed(
                'Confirm!',
                'Are you sure you want to Disable IP restriction?',
                self.SSConfirmationService.TYPE_YES_NO,
                function () {
                    self.IpEnabledCheck = check;
                }
            );
        }
    }

    _setIdAddressField() {
        const self = this;
        self.allowedIps = self.userParams.user_allowed_ips;

        if (self.allowedIps) {
            self.IpEnabledCheck = true;
        } else {
            self.IpEnabledCheck = false;
        }
    }

    _validationIpRestriction() {
        const self = this;

        if (self.IpEnabledCheck) {

            if (self.allowedIps) {

                if (self.allowedIps.length <= 255) {
                    self.userParams.user_allowed_ips = self.allowedIps;
                    self.errIpRestriction = '';
                    return false;
                } else {
                    self.errIpRestriction = 'Max Limit 255 characters';
                    return true;
                }

            } else {
                self.errIpRestriction = 'Please Enter IP Address or Disable the IP Restriction.';
                return true;
            }
        } else {
            self.userParams.user_allowed_ips = '';
            self.allowedIps = '';
            return false;
        }
    }

    showUpdate2FAModal(response) {
        const self = this;

        self.prefix_2fa = (response.data && response.data.prefix_2fa) ? response.data.prefix_2fa : '';
        self.hideModals();
        self.showModal = true;
        $('#updateTwoFAModal').modal('show');
    }

    verifyTwoFA() {
        const self = this;

        self.errTwoFACode = '';

        let params = {
            email: self.user.email,
            twofa_code: self.prefix_2fa + self.twofa_code,
            twofa_type: self.TYPE_ENABLE_2FA
        };

        loading(true);
        self.SSHttpService.postAPIRequest('2fa', params).then(function (responseObj) {
            loading(false);
            if (responseObj instanceof Error) {
                self.SSAlertService.parseAndDisplayError(responseObj);

                self.errTwoFACode = "Invalid Code";
                return;
            }

            self.hideModals();

            self.SSConfirmationService.getConfirmed(
                'Confirm!',
                '2FA updated successfully.',
                self.SSConfirmationService.TYPE_DEFAULT,
                function () {
                    self.user.twofa_enabled = 1;
                }
            );
        });
    }

    hideModals() {
        const self = this;

        self.showModal = false;
        self.showCropPhotoModalPopUp = false;

        if ($('#updateTwoFAModal').is(':visible')) $('#updateTwoFAModal').modal('hide');
        if ($('#cropPhotoModalPopUp').is(':visible')) $('#cropPhotoModalPopUp').modal('hide');
    }

    uploadSignatureFile(files) {
        const self = this;

        if (!files || files.length < 1) return;

        if (!self.userParams.sales_consultants_code) {
            self.SSAlertService.danger("Error!", "Please enter staff id before uploading the signature file.");
            return;
        }

        let file = files[0];
        self.file_name = file.name;

        if (URL) {
            self.displayCropPhotoModal(URL.createObjectURL(file));
        } else if (FileReader) {
            let reader = new FileReader();
            reader.onload = function (e) {
                self.displayCropPhotoModal(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }

    displayCropPhotoModal(url) {
        const self = this;

        self.hideModals();

        self.beforeCropImgUrl = angular.copy(url);

        self.showCropPhotoModalPopUp = true;
        $('#cropPhotoModalPopUp').modal('show');
    }

    _uploadSignatureFile(file_uri, is_remove) {
        const self = this;

        let url = 'sec/developer/profile/sales-consultant';
        let params = {
            code: self.userParams.sales_consultants_code,
            signature_file_uri: file_uri
        };
        loading(true);

        self.SSHttpService.putAPIRequest(url, params).then(function (response) {
            loading(false);
            self.hideModals();

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (is_remove) {
                self.SSAlertService.success('Success!', 'File removed successfully.');
            } else {
                self.SSAlertService.success('Success!', 'File uploaded successfully.');
            }

            if (response.data.sales_consultant.signature_file_uri) {
                self.userParams.sales_consultants_signature_file_uri = response.data.sales_consultant.signature_file_uri;
                self.accessSignatureFile(response.data.sales_consultant.id);
            } else {
                self.userParams.sales_consultants_signature_file_uri = null;
                self.showImageUri = null;
            }
        });
    }

    getSalesConsultant() {
        const self = this;

        let url = 'sec/me';
        let params = {
            include: 'sales_consultant'
        };
        loading(true);

        self.SSHttpService.getAPIRequest(url, params).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.data.sales_consultant) {
                if (response.data.sales_consultant.signature_file_uri) {
                    self.accessSignatureFile(response.data.sales_consultant.id);
                }
                self.userParams.sales_consultants_signature_file_uri = response.data.sales_consultant.signature_file_uri;
                self.userParams.sales_consultants_code = response.data.sales_consultant.code;
            }
        });
    }

    accessSignatureFile(signature_id) {
        const self = this;

        let url = 'sec/assets/presigned-url';
        let params = {
            model: self.ASSET_MODEL_SALES_CONSULTANT_SIGNATURE,
            id: signature_id
        };

        loading(true);
        self.SSHttpService.getAPIRequest(url, params).then(function (responseObj) {
            loading(false);
            if (responseObj instanceof Error) {
                self.SSAlertService.parseAndDisplayError(responseObj);
                return;
            }
            self.showImageUri = responseObj.data.presigned_url;
        });
    }

    removeSignatureFile() {
        const self = this;

        self.SSConfirmationService.getConfirmed(
            'Confirm!',
            'Remove the signature file?',
            self.SSConfirmationService.TYPE_YES_NO,
            function () {
                self._uploadSignatureFile('', true);
            }
        );
    }

    cropImage() {
        const self = this;

        let canvas = SHOWSUITE.cropper.getCroppedCanvas();

        canvas.toBlob(function (file) {
            file.name = self.file_name;

            let policyUrl = 'sec/developer/profile/sales-consultant/s3-policy-document';

            loading(true);
            self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.hideModals();
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }

                self._uploadSignatureFile(response.config.data.key);
            });
        });
    }

    getDeveloperSettings() {
        const self = this;

        let url = 'sec/developer/settings';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            if (response.data && response.data.developer_settings) {
                self.SSUserService.password_validation = response.data.developer_settings.some(x => x.name == self.DEVELOPER_SETTINGS_PASSWORD_VALIDATION)? self.getSetting(response.data.developer_settings, self.DEVELOPER_SETTINGS_PASSWORD_VALIDATION).value: null;
            }
        });
    }

    getSetting(settings, setting_name) {
        const self = this;

        if (!(settings && setting_name)) {
            return null;
        }

        let setting = {};

        try {
            setting = settings.find(x => x.name == setting_name);

            if (setting
                && setting.value
                && typeof setting.value == "string"
            ) {
                setting.value = JSON.parse(setting.value);
            }
        } catch (ex) {
            console.log(ex);
        }

        return setting;
    }

}

AdminProfileController.$inject = ['$element', '$parse', '$timeout', 'SSHttpService', 'SSUtilService', 'SSAlertService', 'OTPService', 'SSUserService', 'SSConfirmationService', 'SSAwsService', 'SSAppService'];
app.controller('AdminProfileController', AdminProfileController);
