"use strict";

class BreadcrumbsController {
    constructor(SSUtilService, SSUserService) {
        const self = this;

        self.SSUtilService = SSUtilService;
        self.SSUserService = SSUserService;

        self.data = SHOWSUITE.data;
        self.property = SHOWSUITE.data.property;

        self.init();
    }

    init() {
        const self = this;

    }
}

BreadcrumbsController.$inject = ['SSUtilService', 'SSUserService'];
app.controller('BreadcrumbsController', BreadcrumbsController);
